/* cyrillic-ext */
@font-face {
  font-family: '__Inter_46a1ea';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_46a1ea';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_46a1ea';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_46a1ea';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_46a1ea';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_46a1ea';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_46a1ea';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/c9a5bc6a7c948fb0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_46a1ea';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_46a1ea {font-family: '__Inter_46a1ea', '__Inter_Fallback_46a1ea', system-ui, arial;font-style: normal
}.__variable_46a1ea {--font-content: '__Inter_46a1ea', '__Inter_Fallback_46a1ea', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_e38312';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/ebec2867f40f78ec-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_e38312';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/d607327a37a507c7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_e38312';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/2e1b830192b7974a-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_e38312';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3aa27b2eb5f698f7-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_e38312';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3478b6abef19b3b3-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_e38312';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/97b12f7b815cdf76-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_e38312';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/19fc70611c7ee6d5-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_e38312';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/01721b474504e7d6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_e38312';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3ccf24bed29cbb82-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_e38312';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/be2416cbb012c256-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Mono_Fallback_e38312';src: local("Arial");ascent-override: 75.41%;descent-override: 20.23%;line-gap-override: 0.00%;size-adjust: 135.93%
}.__className_e38312 {font-family: '__IBM_Plex_Mono_e38312', '__IBM_Plex_Mono_Fallback_e38312', monospace;font-style: normal
}.__variable_e38312 {--font-mono: '__IBM_Plex_Mono_e38312', '__IBM_Plex_Mono_Fallback_e38312', monospace
}

/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4b5317f5d33d7777-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6229d45b32b9328e-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/5d775c9d084b94a6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/ab344fe54992ad1a-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/2ffbbd3601d7a925-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/78c1df28fc00c891-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/500554082cafa35a-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0c1d5536adb221e7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/2bed6b8169258e4a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/31ce3053e521f1d2-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4eef596e1e6e9d95-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/09ca9e7fa9fac0e7-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6c94a934ef7856d4-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3ca07d8704d03f09-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/81b238903059be90-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/1b31eacdf9f7dd73-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/cf166282f6a0cc9e-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/d960e2aae5efcef3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a786a22cd12c67b7-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/1d91333027dd8ca8-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/c944b765f2f70748-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/40d8f6ced937d546-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/c02e77600ed6c2ab-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/21ab7a1b68e1c648-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/393f3d6bd66917ed-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/deda211e4ad86307-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0f85484166f4dde6-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_Extra_Condensed_820890';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3256b0abf83d177d-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Fira_Sans_Extra_Condensed_Fallback_820890';src: local("Arial");ascent-override: 108.90%;descent-override: 30.86%;line-gap-override: 0.00%;size-adjust: 85.86%
}.__className_820890 {font-family: '__Fira_Sans_Extra_Condensed_820890', '__Fira_Sans_Extra_Condensed_Fallback_820890', system-ui, arial;font-style: normal
}.__variable_820890 {--font-content: '__Fira_Sans_Extra_Condensed_820890', '__Fira_Sans_Extra_Condensed_Fallback_820890', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/1831d554f4a6c72e-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4bb7c4805b03088f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0bf2851e5c3c33fd-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0a68262bca23ef37-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4a55e3a359b42768-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6222c70ac70e330d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7179dc0a1e7f6876-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/bcd7ed7b8307a5f1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f259b773f7602f5c-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/301c9070498588dc-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/412dd43c0b8d0102-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/d0ae519964ce1eb9-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0a5e1f922de155b5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/78bcd4db008a6130-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Serif_51464d';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/5a38c412fa3989d0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Serif_Fallback_51464d';src: local("Times New Roman");ascent-override: 87.59%;descent-override: 23.50%;line-gap-override: 0.00%;size-adjust: 117.03%
}.__className_51464d {font-family: '__IBM_Plex_Serif_51464d', '__IBM_Plex_Serif_Fallback_51464d', serif;font-style: normal
}.__variable_51464d {--font-content: '__IBM_Plex_Serif_51464d', '__IBM_Plex_Serif_Fallback_51464d', serif
}

/* latin-ext */
@font-face {
  font-family: '__Lato_9e9831';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f1df658da56627d0-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lato_9e9831';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4de1fea1a954a5b6-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Lato_9e9831';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/162938472036e0a8-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lato_9e9831';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6d664cce900333ee-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Lato_9e9831';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/af4d27004aa34222-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lato_9e9831';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7ff6869a1704182a-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lato_Fallback_9e9831';src: local("Arial");ascent-override: 101.32%;descent-override: 21.87%;line-gap-override: 0.00%;size-adjust: 97.42%
}.__className_9e9831 {font-family: '__Lato_9e9831', '__Lato_Fallback_9e9831', system-ui, arial;font-style: normal
}.__variable_9e9831 {--font-content: '__Lato_9e9831', '__Lato_Fallback_9e9831', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/07103e16d41c9190-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6dc02f179ba8da8f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9450a5aa688b86af-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f1d4c48219b1bd72-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/931105f8d96e7f26-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/bd2489da38fb1d0b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/84a39d905077a976-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0fb93cc812fb4b50-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/32702a3715dbd7c1-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7b9ca6a1d31c5662-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/ef3f4e253ce73be8-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f0b16be9a95e8820-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f2988470e2e746be-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/13017c58cf72aedd-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Merriweather_004a84';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/c7a495162773a63f-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Merriweather_Fallback_004a84';src: local("Times New Roman");ascent-override: 80.31%;descent-override: 22.28%;line-gap-override: 0.00%;size-adjust: 122.53%
}.__className_004a84 {font-family: '__Merriweather_004a84', '__Merriweather_Fallback_004a84', serif;font-style: normal
}.__variable_004a84 {--font-content: '__Merriweather_004a84', '__Merriweather_Fallback_004a84', serif
}

/* cyrillic-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4221e1667cd19c7d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9d9319a7a2ac39c6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f759c939737fb668-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}
/* greek-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/abce7c400ca31a51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6cc0b9500e4f9168-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a75c8ea86756d52d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6c276159aa0eb14b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0e790e04fd40ad16-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4221e1667cd19c7d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9d9319a7a2ac39c6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f759c939737fb668-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}
/* greek-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/abce7c400ca31a51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6cc0b9500e4f9168-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a75c8ea86756d52d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6c276159aa0eb14b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0e790e04fd40ad16-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4221e1667cd19c7d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9d9319a7a2ac39c6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f759c939737fb668-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}
/* greek-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/abce7c400ca31a51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6cc0b9500e4f9168-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a75c8ea86756d52d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6c276159aa0eb14b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0e790e04fd40ad16-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4221e1667cd19c7d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9d9319a7a2ac39c6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f759c939737fb668-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}
/* greek-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/abce7c400ca31a51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6cc0b9500e4f9168-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a75c8ea86756d52d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6c276159aa0eb14b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Noto_Sans_be3f9d';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0e790e04fd40ad16-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Noto_Sans_Fallback_be3f9d';src: local("Arial");ascent-override: 100.18%;descent-override: 27.46%;line-gap-override: 0.00%;size-adjust: 106.70%
}.__className_be3f9d {font-family: '__Noto_Sans_be3f9d', '__Noto_Sans_Fallback_be3f9d', system-ui, arial;font-style: normal
}.__variable_be3f9d {--font-content: '__Noto_Sans_be3f9d', '__Noto_Sans_Fallback_be3f9d', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/83651bee47cf14da-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6fed4e5749a3ea15-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/062522b8b7c3ad6a-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e6f5886ae1242622-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* hebrew */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/19e37deead9b3ec2-s.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* math */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/46392699924ae7e5-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0330, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2034-2037, U+2057, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2102, U+210A-210E, U+2110-2112, U+2115, U+2119-211D, U+2124, U+2128, U+212C-212D, U+212F-2131, U+2133-2138, U+213C-2140, U+2145-2149, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B6, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/dd4ab5b525bd804a-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8B1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA88, U+1FA90-1FABD, U+1FABF-1FAC5, U+1FACE-1FADB, U+1FAE0-1FAE8, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9beef36ab83de3f0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/faac4ac11aa3d97b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3d9ea938b6afa941-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/83651bee47cf14da-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6fed4e5749a3ea15-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/062522b8b7c3ad6a-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e6f5886ae1242622-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* hebrew */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/19e37deead9b3ec2-s.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* math */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/46392699924ae7e5-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0330, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2034-2037, U+2057, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2102, U+210A-210E, U+2110-2112, U+2115, U+2119-211D, U+2124, U+2128, U+212C-212D, U+212F-2131, U+2133-2138, U+213C-2140, U+2145-2149, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B6, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/dd4ab5b525bd804a-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8B1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA88, U+1FA90-1FABD, U+1FABF-1FAC5, U+1FACE-1FADB, U+1FAE0-1FAE8, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9beef36ab83de3f0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/faac4ac11aa3d97b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3d9ea938b6afa941-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/83651bee47cf14da-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6fed4e5749a3ea15-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/062522b8b7c3ad6a-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e6f5886ae1242622-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* hebrew */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/19e37deead9b3ec2-s.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* math */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/46392699924ae7e5-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0330, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2034-2037, U+2057, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2102, U+210A-210E, U+2110-2112, U+2115, U+2119-211D, U+2124, U+2128, U+212C-212D, U+212F-2131, U+2133-2138, U+213C-2140, U+2145-2149, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B6, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/dd4ab5b525bd804a-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8B1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA88, U+1FA90-1FABD, U+1FABF-1FAC5, U+1FACE-1FADB, U+1FAE0-1FAE8, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9beef36ab83de3f0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/faac4ac11aa3d97b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3d9ea938b6afa941-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/83651bee47cf14da-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/6fed4e5749a3ea15-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/062522b8b7c3ad6a-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e6f5886ae1242622-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* hebrew */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/19e37deead9b3ec2-s.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* math */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/46392699924ae7e5-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0330, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2034-2037, U+2057, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2102, U+210A-210E, U+2110-2112, U+2115, U+2119-211D, U+2124, U+2128, U+212C-212D, U+212F-2131, U+2133-2138, U+213C-2140, U+2145-2149, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B6, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/dd4ab5b525bd804a-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8B1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA88, U+1FA90-1FABD, U+1FABF-1FAC5, U+1FACE-1FADB, U+1FAE0-1FAE8, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9beef36ab83de3f0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/faac4ac11aa3d97b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Open_Sans_cc5619';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3d9ea938b6afa941-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Open_Sans_Fallback_cc5619';src: local("Arial");ascent-override: 101.18%;descent-override: 27.73%;line-gap-override: 0.00%;size-adjust: 105.64%
}.__className_cc5619 {font-family: '__Open_Sans_cc5619', '__Open_Sans_Fallback_cc5619', system-ui, arial;font-style: normal
}.__variable_cc5619 {--font-content: '__Open_Sans_cc5619', '__Open_Sans_Fallback_cc5619', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__Overpass_948b77';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7499d1fd488b7c5a-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Overpass_948b77';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/ee9982dce2c87a23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Overpass_948b77';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/5f1343702accbb98-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Overpass_948b77';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a2e9a53c90d0af0f-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Overpass_948b77';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/83b078698f446a08-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Overpass_Fallback_948b77';src: local("Arial");ascent-override: 87.69%;descent-override: 38.03%;line-gap-override: 0.00%;size-adjust: 100.70%
}.__className_948b77 {font-family: '__Overpass_948b77', '__Overpass_Fallback_948b77', system-ui, arial;font-style: normal
}.__variable_948b77 {--font-content: '__Overpass_948b77', '__Overpass_Fallback_948b77', system-ui, arial
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_e6e8f1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/46c21389e888bf13-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e6e8f1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/eafabf029ad39a43-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_e6e8f1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f5767adec246cdc1-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e6e8f1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/8888a3826f4a3af4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_e6e8f1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0a03a6d30c07af2e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e6e8f1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0484562807a97172-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_e6e8f1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/30cd8f99d32fa6e8-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e6e8f1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/b957ea75a84b6ea7-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_e6e8f1';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_e6e8f1 {font-family: '__Poppins_e6e8f1', '__Poppins_Fallback_e6e8f1', system-ui, arial;font-style: normal
}.__variable_e6e8f1 {--font-content: '__Poppins_e6e8f1', '__Poppins_Fallback_e6e8f1', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/200388358b398524-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/34900c74a84112b6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/10dadb2e82d03733-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/5f2068c3133468f5-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9bf67a161a796382-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/200388358b398524-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/34900c74a84112b6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/10dadb2e82d03733-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/5f2068c3133468f5-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9bf67a161a796382-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/200388358b398524-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/34900c74a84112b6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/10dadb2e82d03733-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/5f2068c3133468f5-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9bf67a161a796382-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/200388358b398524-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/34900c74a84112b6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/10dadb2e82d03733-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/5f2068c3133468f5-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Raleway_06e3e9';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9bf67a161a796382-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Raleway_Fallback_06e3e9';src: local("Arial");ascent-override: 89.62%;descent-override: 22.31%;line-gap-override: 0.00%;size-adjust: 104.89%
}.__className_06e3e9 {font-family: '__Raleway_06e3e9', '__Raleway_Fallback_06e3e9', system-ui, arial;font-style: normal
}.__variable_06e3e9 {--font-content: '__Raleway_06e3e9', '__Raleway_Fallback_06e3e9', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7c53f7419436e04b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/1c57ca6f5208a29b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/cff529cd86cc0276-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7be645d133f3ee22-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/5647e4c23315a2d2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3dbd163d3bb09d47-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/934c4b7cb736f2a3-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/dfa8b99978df7bbc-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/42d52f46a26971a3-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a6d330d7873e7320-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a5b77b63ef20339c-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/d117eea74e01de14-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/eb52b768f62eeeb4-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/627622453ef56b0d-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e25729ca87cc7df9-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/912a9cfe43c928d9-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/baf12dd90520ae41-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/bbdb6f0234009aba-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f06116e890b3dadb-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/8fb72f69fba4e3d2-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_0db11f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0e4fe491bf84089c-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_0db11f';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_0db11f {font-family: '__Roboto_0db11f', '__Roboto_Fallback_0db11f', system-ui, arial;font-style: normal
}.__variable_0db11f {--font-content: '__Roboto_0db11f', '__Roboto_Fallback_0db11f', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e8e25437b49f95eb-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/1503d3bdd3f79a19-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e2a272b6447367f1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/be657e7655c1bafa-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/468b79e2d56358f9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/b0da285322ee6896-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/d3d85d86ad5c431a-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e8e25437b49f95eb-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/1503d3bdd3f79a19-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e2a272b6447367f1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/be657e7655c1bafa-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/468b79e2d56358f9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/b0da285322ee6896-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/d3d85d86ad5c431a-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e8e25437b49f95eb-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/1503d3bdd3f79a19-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e2a272b6447367f1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/be657e7655c1bafa-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/468b79e2d56358f9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/b0da285322ee6896-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Slab_298694';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/d3d85d86ad5c431a-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Slab_Fallback_298694';src: local("Times New Roman");ascent-override: 88.56%;descent-override: 22.90%;line-gap-override: 0.00%;size-adjust: 118.32%
}.__className_298694 {font-family: '__Roboto_Slab_298694', '__Roboto_Slab_Fallback_298694', system-ui, arial;font-style: normal
}.__variable_298694 {--font-content: '__Roboto_Slab_298694', '__Roboto_Slab_Fallback_298694', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a9b61b60c2d733b4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a9b61b60c2d733b4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_18fd50';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/a9b61b60c2d733b4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_3_Fallback_18fd50';src: local("Arial");ascent-override: 108.65%;descent-override: 42.44%;line-gap-override: 0.00%;size-adjust: 94.24%
}.__className_18fd50 {font-family: '__Source_Sans_3_18fd50', '__Source_Sans_3_Fallback_18fd50', system-ui, arial;font-style: normal
}.__variable_18fd50 {--font-content: '__Source_Sans_3_18fd50', '__Source_Sans_3_Fallback_18fd50', system-ui, arial
}

/* cyrillic-ext */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/cb6f448281d0c282-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/0cf580864ae59341-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/fb3d4f9492d39101-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/b3e13a4ac57b538e-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/8bb90efd1b2442e0-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f884d4ea94220255-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f98b5748eac51d35-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/f9e3f1f7478b5cbf-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/2ffff5335cee8907-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/cfcbe5037985cfe8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7eba5a0cf69ea5af-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e76854c588de135c-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/d4f7cefda76b2fff-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/d51be7479414f4fc-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/3be63adebbe80e17-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/7e2a926bef88262a-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/86ba892d94d0e659-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Ubuntu_537439';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://static.gitbook.com/_next/static/media/e94cfb95acf534f2-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Ubuntu_Fallback_537439';src: local("Arial");ascent-override: 90.81%;descent-override: 18.42%;line-gap-override: 2.73%;size-adjust: 102.63%
}.__className_537439 {font-family: '__Ubuntu_537439', '__Ubuntu_Fallback_537439', system-ui, arial;font-style: normal
}.__variable_537439 {--font-content: '__Ubuntu_537439', '__Ubuntu_Fallback_537439', system-ui, arial
}

@font-face {
ascent-override: 100%;
font-family: '__abcFavorit_278371';
src: url(https://static.gitbook.com/_next/static/media/304c035dd6e7fc45-s.woff2) format('woff2');
font-display: swap;
font-weight: 400 700;
font-style: normal;
}

@font-face {
ascent-override: 100%;
font-family: '__abcFavorit_278371';
src: url(https://static.gitbook.com/_next/static/media/af2d7ef9b353e758-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
ascent-override: 100%;
font-family: '__abcFavorit_278371';
src: url(https://static.gitbook.com/_next/static/media/cab3700fee18b5d1-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
ascent-override: 100%;
font-family: '__abcFavorit_278371';
src: url(https://static.gitbook.com/_next/static/media/224ae988fdf9f2c5-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__abcFavorit_Fallback_278371';src: local("Arial");ascent-override: 90.97%;descent-override: 37.34%;line-gap-override: 0.00%;size-adjust: 104.43%
}.__className_278371 {font-family: '__abcFavorit_278371', '__abcFavorit_Fallback_278371', system-ui, arial
}.__variable_278371 {--font-content: '__abcFavorit_278371', '__abcFavorit_Fallback_278371', system-ui, arial
}

@font-face {
font-family: '__svgFont_d78c7f';
src: url(https://static.gitbook.com/_next/static/media/79ec87d3cdff1fa5-s.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__svgFont_Fallback_d78c7f';src: local("Arial");ascent-override: 83.01%;descent-override: 14.65%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_d78c7f {font-family: '__svgFont_d78c7f', '__svgFont_Fallback_d78c7f'
}.__variable_d78c7f {--font-emojis-svg: '__svgFont_d78c7f', '__svgFont_Fallback_d78c7f'
}

@font-face {
font-family: '__sbixFont_0034ec';
src: url(https://static.gitbook.com/_next/static/media/8c5a8b58a82efc8e-s.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__sbixFont_Fallback_0034ec';src: local("Arial");ascent-override: 83.01%;descent-override: 14.65%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_0034ec {font-family: '__sbixFont_0034ec', '__sbixFont_Fallback_0034ec'
}.__variable_0034ec {--font-emojis-sbix: '__sbixFont_0034ec', '__sbixFont_Fallback_0034ec'
}

@font-face {
font-family: '__cbdtFont_774aa7';
src: url(https://static.gitbook.com/_next/static/media/332370fdb30dcf2a-s.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__cbdtFont_Fallback_774aa7';src: local("Arial");ascent-override: 83.01%;descent-override: 14.65%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_774aa7 {font-family: '__cbdtFont_774aa7', '__cbdtFont_Fallback_774aa7'
}.__variable_774aa7 {--font-emojis-cbdt: '__cbdtFont_774aa7', '__cbdtFont_Fallback_774aa7'
}

